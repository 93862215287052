<template>
  <el-main>
    <div class="p15 pt0">
      <el-form :inline="true" class="demo-form-inline">
        <div class="df" style="margin-top: 20px">
          <el-form-item size="medium" style="flex: 0 0 15%">
            <div style="width: 100%">
              <el-input
                placeholder="模板名称"
                v-model="query.keywords"
                clearable
                class="input-with-select"
                @clear="onSubmit(page.shipping_sn)"
              >
              </el-input>
            </div>
          </el-form-item>

          <el-button
            type="primary"
            size="medium"
            @click="onSubmit"
            style="height: 34px; border: none !important; margin-top: 1px"
            >查询</el-button
          >
          <el-button
            style="height: 34px; border: none; margin-top: 1px"
            size="medium"
            type="warning"
            @click="showAdd = true"
            >新增模板</el-button
          >
        </div>
      </el-form>
      <div class="box list">
        <el-table border :data="tableData" style="width: 100%" id="out-table">
          <el-table-column
            prop="name"
            min-width="100"
            :show-overflow-tooltip="true"
            label="模板名称"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="createdTime"
            min-width="120"
            :show-overflow-tooltip="true"
            label="创建时间"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="real_pics"
            min-width="160"
            :show-overflow-tooltip="true"
            label="发货实景"
            align="center"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="openEdit(scope.row)"
                >修改</el-button
              >
              <el-popconfirm
                title="这是一段内容确定删除吗？"
                @confirm="handleDel(scope.row)"
              >
                <el-button
                  slot="reference"
                  type="text"
                  style="margin-left: 10px; color: #f56c6c"
                  >删除</el-button
                >
              </el-popconfirm>
              <el-popover placement="left" width="440" trigger="click">
                <div style="padding: 10px">
                  <el-date-picker
                    @change="timeChoose"
                    v-model="timeValue"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                  <div class="df aic jcc" style="margin-top: 10px">
                    <el-button
                      type="primary"
                      size="mini"
                      @click="handleExport(scope.row)"
                      >确认导出</el-button
                    >
                  </div>
                </div>
                <el-button
                  type="text"
                  slot="reference"
                  style="margin-left: 10px; color: #67c23a"
                  >导出</el-button
                >
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
        <div class="i-page fr disib mt20">
          <el-pagination
            background
            :page-size="10"
            layout="total,prev, pager, next"
            :total="total"
            :current-page="query.page"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      title="新增模板"
      :visible.sync="showAdd"
      width="70%"
      class="add-dialog"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="96px"
        :rules="rules"
        v-if="showAdd"
      >
        <el-form-item label="模板名称" style="width: 100%" prop="name">
          <div style="width: 30%">
            <el-input v-model="form.name" clearable></el-input>
          </div>
        </el-form-item>
        <el-form-item label="导出类型" style="width: 100%" prop="type">
          <div style="width: 30%">
            <el-select
              v-model="form.type"
              placeholder="请选择"
              @change="typeChange"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item
          label="系统字段名"
          style="width: 100%"
          v-if="form.type !== ''"
        >
          <div
            style="color: #909399; font-size: 16px; line-height: 16px"
            v-if="myArray.length"
          >
            鼠标拖拽可改变排列顺序
          </div>
          <div style="width: 96%">
            <draggable
              v-model="myArray"
              chosen-class="chosen"
              force-fallback="true"
              sort="true"
              group="people"
              animation="1000"
              @start="onStart"
              @end="onEnd"
            >
              <transition-group class="df" style="width: 100%; flex-wrap: wrap">
                <div
                  class="item"
                  v-for="(element, elementIndex) in myArray"
                  :key="element.id"
                  style="flex: 0 0 20%"
                >
                  <div class="drag-box">
                    <div class="unselectable df">
                      <div style="flex: 1">
                        {{ element.name }}
                      </div>
                      <div>
                        <i
                          @click="handleDelete(elementIndex)"
                          class="el-icon-error choose-box"
                          style="color: #f56c6c; font-size: 26px"
                        ></i>
                      </div>
                    </div>
                    <div>
                      <el-form
                        label-width="90px"
                        ref="ruleItemForm"
                        :rules="rules"
                        :model="element"
                      >
                        <el-form-item label="导出字段名" prop="exportName">
                          <el-input
                            v-model="element.exportName"
                            clearable
                          ></el-input>
                        </el-form-item>
                      </el-form>
                    </div>
                  </div>
                </div>
              </transition-group>
            </draggable>
          </div>
          <div>
            <el-popover
              placement="top"
              width="200"
              trigger="click"
              v-model="showAddPopver"
              @show="handleOpenPopver"
            >
              <div
                style="
                  height: 360px;
                  overflow-y: scroll;
                  background-color: #eee;
                "
                class="scroll-box"
              >
                <div
                  v-for="(item, index) in popoverList"
                  class="unselectable choose-box"
                  :key="index"
                  @click="handleChoose(item)"
                  style="
                    line-height: 20px;
                    border-radius: 5px;
                    margin: 3px;
                    background-color: #fff;
                    padding: 5px 20px;
                  "
                >
                  {{ item.label }}
                </div>
              </div>
              <el-button slot="reference" type="primary" size="mini"
                >新增导出字段</el-button
              >
            </el-popover>
          </div>
        </el-form-item>
      </el-form>
      <el-button type="success" @click="handleSubmit">提交</el-button>
    </el-dialog>
    <el-dialog
      title="修改模板"
      :visible.sync="showEdit"
      width="70%"
      class="add-dialog"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="96px"
        :rules="rules"
        v-if="showEdit"
      >
        <el-form-item label="模板名称" style="width: 100%" prop="name">
          <div style="width: 30%">
            <el-input v-model="form.name" clearable></el-input>
          </div>
        </el-form-item>
        <el-form-item label="导出类型" style="width: 100%" prop="type">
          <div style="width: 30%">
            <el-select
              v-model="form.type"
              placeholder="请选择"
              @change="typeChange"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item
          label="系统字段名"
          style="width: 100%"
          v-if="form.type !== ''"
        >
          <div
            style="color: #909399; font-size: 16px; line-height: 16px"
            v-if="myArray.length"
          >
            鼠标拖拽可改变排列顺序
          </div>
          <div style="width: 96%">
            <draggable
              v-model="myArray"
              chosen-class="chosen"
              force-fallback="true"
              sort="true"
              group="people"
              animation="1000"
              @start="onStart"
              @end="onEnd"
            >
              <transition-group class="df" style="width: 100%; flex-wrap: wrap">
                <div
                  class="item"
                  v-for="(element, elementIndex) in myArray"
                  :key="element.id"
                  style="flex: 0 0 20%"
                >
                  <div class="drag-box">
                    <div class="unselectable df">
                      <div style="flex: 1">
                        {{ element.name }}
                      </div>
                      <div>
                        <i
                          @click="handleDelete(elementIndex)"
                          class="el-icon-error choose-box"
                          style="color: #f56c6c; font-size: 26px"
                        ></i>
                      </div>
                    </div>
                    <div>
                      <el-form
                        label-width="90px"
                        ref="ruleItemForm"
                        :rules="rules"
                        :model="element"
                      >
                        <el-form-item label="导出字段名" prop="exportName">
                          <el-input
                            v-model="element.exportName"
                            clearable
                          ></el-input>
                        </el-form-item>
                      </el-form>
                    </div>
                  </div>
                </div>
              </transition-group>
            </draggable>
          </div>
          <div>
            <el-popover
              placement="top"
              width="200"
              trigger="click"
              v-model="showAddPopver"
              @show="handleOpenPopver"
            >
              <div
                style="
                  height: 360px;
                  overflow-y: scroll;
                  background-color: #eee;
                "
                class="scroll-box"
              >
                <div
                  v-for="(item, index) in popoverList"
                  class="unselectable choose-box"
                  :key="index"
                  @click="handleChoose(item)"
                  style="
                    line-height: 20px;
                    border-radius: 5px;
                    margin: 3px;
                    background-color: #fff;
                    padding: 5px 20px;
                  "
                >
                  {{ item.label }}
                </div>
              </div>
              <el-button slot="reference" type="primary" size="mini"
                >新增导出字段</el-button
              >
            </el-popover>
          </div>
        </el-form-item>
      </el-form>
      <el-button type="success" @click="handleEdit">修改</el-button>
    </el-dialog>
  </el-main>
</template>

<script>
import Draggable from "vuedraggable";
import api from "../../../util/extra-api";
import { local } from "@/util/util";
import dayjs from "dayjs";

export default {
  data() {
    return {
      showAdd: false,
      query: {
        keywords: "",
        page: 1,
        limit: 20,
      },
      popoverList: [],
      tableData: [],
      total: 0,
      showAddPopver: false,
      myArray: [],
      sendList: [
        {
          label: "商品名称",
          value: "goodsName",
          remark: "",
        },
        {
          label: "发货单号",
          value: "shippingSn",
          remark: "",
        },
        {
          label: "发货状态",
          value: "status",
          remark:
            "pending:待收货,shipped:已发货,invalid:已作废,finished:已完成,returned:已退货",
        },
        {
          label: "退货状态",
          value: "returnGoods",
          remark:
            "apply:退货申请中,refuse:拒绝,returning:待退货,returned:退货中,finished:已退货,error:退货失败",
        },
        {
          label: "发货时间",
          value: "shipTime",
          remark: "",
        },
        {
          label: "品码",
          value: "gtin",
          remark: "",
        },
        {
          label: "品码信息",
          value: "gtinInfo",
          remark: "",
        },
        {
          label: "商品批次号",
          value: "batchNumber",
          remark: "",
        },
        {
          label: "货品总重量(kg)",
          value: "goodsWeight",
          remark: "",
        },
        {
          label: "单个货品重量(kg)",
          value: "goodsWeightUnit",
          remark: "",
        },
        {
          label: "货品总数",
          value: "goodsSum",
          remark: "",
        },
        {
          label: "货品件数",
          value: "goodsNumber",
          remark: "",
        },
        {
          label: "货品零散数量",
          value: "goodsItem",
          remark: "",
        },
        {
          label: "货品单位",
          value: "goodsUnit",
          remark: "",
        },
        {
          label: "货品总价(元)",
          value: "goodsTotalPrice",
          remark: "",
        },
        {
          label: "货品单价(元)",
          value: "goodsUnitPrice",
          remark: "",
        },
        {
          label: "运输车辆车牌",
          value: "carNumber",
          remark: "",
        },
        {
          label: "挂车车牌号",
          value: "guaCarNumber",
          remark: "",
        },
        {
          label: "库房名称",
          value: "warehouseName",
          remark: "",
        },
        {
          label: "发货企业名称",
          value: "companyName",
          remark: "",
        },
        {
          label: "收货企业名称",
          value: "consigneeCompanyName",
          remark: "",
        },
        {
          label: "备注",
          value: "remark",
          remark: "",
        },
        {
          label: "作废原因",
          value: "reason",
          remark: "",
        },
        {
          label: "作废说明",
          value: "explanation",
          remark: "",
        },
        {
          label: "作废时间",
          value: "explanationTime",
          remark: "",
        },
        {
          label: "创建时间",
          value: "createdTime",
          remark: "",
        },
        {
          label: "创建人姓名",
          value: "createdName",
          remark: "",
        },
        {
          label: "创建人手机号",
          value: "createdPhone",
          remark: "",
        },
        {
          label: "作废操作员姓名",
          value: "canceledName",
          remark: "",
        },
        {
          label: "创建人手机号",
          value: "canceledPhone",
          remark: "",
        },
        {
          label: "实际发货人姓名",
          value: "consignerName",
          remark: "",
        },
        {
          label: "实际发货人手机",
          value: "consignerPhone",
          remark: "",
        },
        {
          label: "发货人经纬度",
          value: "consignerCoordinate",
          remark: "",
        },
        {
          label: "生产日期",
          value: "dateOfManufacture",
          remark: "",
        },
        {
          label: "保质期",
          value: "qualityGuaranteePeriod",
          remark: "",
        },
        {
          label: "更新时间",
          value: "updatedTime",
          remark: "",
        },
        {
          label: "结算标识",
          value: "endTag",
          remark: "0:未结算,1:已结算",
        },
      ],
      receiptList: [
        {
          label: "商品名称",
          value: "goodsName",
          remark: "",
        },
        {
          label: "收货单号",
          value: "receiptSn",
          remark: "",
        },
        {
          label: "收货状态",
          value: "status",
          remark:
            "pending:待收货,received:已收货,invalid:已作废,finished:已完成,returned:已退货,acceptancing:待验收,failed:验收失败",
        },
        {
          label: "收货时间",
          value: "receiveTime",
          remark: "",
        },
        {
          label: "退货状态",
          value: "returnGoods",
          remark:
            "apply:退货申请中,refuse:拒绝,returning:待退货,returned:退货中,finished:已退货",
        },
        {
          label: "品码",
          value: "gtin",
          remark: "",
        },
        {
          label: "品码信息",
          value: "gtinInfo",
          remark: "",
        },
        {
          label: "商品批次号",
          value: "batchNumber",
          remark: "",
        },
        {
          label: "货品总重量(kg)",
          value: "goodsWeight",
          remark: "",
        },
        {
          label: "单个货品重量(kg)",
          value: "goodsWeightUnit",
          remark: "",
        },
        {
          label: "货品总数",
          value: "goodsSum",
          remark: "",
        },
        {
          label: "货品件数",
          value: "goodsNumber",
          remark: "",
        },
        {
          label: "货品零散数量",
          value: "goodsItem",
          remark: "",
        },
        {
          label: "货品单位",
          value: "goodsUnit",
          remark: "",
        },
        {
          label: "货品总价(元)",
          value: "goodsTotalPrice",
          remark: "",
        },
        {
          label: "货品单价(元)",
          value: "goodsUnitPrice",
          remark: "",
        },
        {
          label: "运输车辆车牌",
          value: "carNumber",
          remark: "",
        },
        {
          label: "挂车车牌号",
          value: "guaCarNumber",
          remark: "",
        },
        {
          label: "库房名称",
          value: "warehouseName",
          remark: "",
        },
        {
          label: "发货企业名称",
          value: "companyName",
          remark: "",
        },
        {
          label: "发货企业名称",
          value: "consignerCompanyName",
          remark: "",
        },
        {
          label: "备注",
          value: "remark",
          remark: "",
        },
        {
          label: "作废或拒收原因",
          value: "reason",
          remark: "",
        },
        {
          label: "验收备注",
          value: "acceptanceRemark",
          remark: "",
        },
        {
          label: "验收人姓名",
          value: "acceptanceName",
          remark: "",
        },
        {
          label: "作废说明",
          value: "explanation",
          remark: "",
        },
        {
          label: "作废时间",
          value: "explanationTime",
          remark: "",
        },
        {
          label: "创建时间",
          value: "createdTime",
          remark: "",
        },
        {
          label: "创建人姓名",
          value: "createdName",
          remark: "",
        },
        {
          label: "创建人手机号",
          value: "createdPhone",
          remark: "",
        },
        {
          label: "作废操作员姓名",
          value: "canceledName",
          remark: "",
        },
        {
          label: "创建人手机号",
          value: "canceledPhone",
          remark: "",
        },
        {
          label: "实际收货人姓名",
          value: "consigneeName",
          remark: "",
        },
        {
          label: "实际收货人手机",
          value: "consigneePhone",
          remark: "",
        },
        {
          label: "收货人经纬度",
          value: "consigneeCoordinate",
          remark: "",
        },
        {
          label: "生产日期",
          value: "dateOfManufacture",
          remark: "",
        },
        {
          label: "保质期",
          value: "qualityGuaranteePeriod",
          remark: "",
        },
        {
          label: "更新时间",
          value: "updatedTime",
          remark: "",
        },
        {
          label: "结算标识",
          value: "endTag",
          remark: "0:未结算,1:已结算",
        },
      ],
      ReimbursementList: [
        {
          label: "金额",
          value: "money",
          remark: "",
        },
        {
          label: "创建时间",
          value: "createdTime",
          remark: "",
        },
        {
          label: "创建人名称",
          value: "createdName",
          remark: "",
        },
        {
          label: "挂账企业名称",
          value: "gCompanyName",
          remark: "",
        },
        {
          label: "账单名",
          value: "name",
          remark: "",
        },
        {
          label: "审核状态",
          value: "auditStatus",
          remark:
            "approved:通过审核,reimbursed:已报账,rejected:已驳回,audit:待审核",
        },
        {
          label: "是否审核",
          value: "audit",
          remark: "true:需要审核,false:无需审核",
        },
        {
          label: "收入支出类型",
          value: "type",
          remark: "0:收入,1:支出",
        },
        {
          label: "修改时间",
          value: "updatedTime",
          remark: "",
        },
        {
          label: "备注",
          value: "remark",
          remark: "",
        },
        {
          label: "驳回原因",
          value: "rejectedContent",
          remark: "",
        },
        {
          label: "收入/支出时间",
          value: "tallyTime",
          remark: "",
        },
        {
          label: "是否已付款",
          value: "isReal",
          remark: "true:已付款,false:未付款",
        },
        {
          label: "报销结果",
          value: "bxResult",
          remark: "0:未通过,1:通过",
        },
      ],
      form: {
        name: "",
        type: "",
        data: [],
      },
      dragAble: false,
      options: [
        {
          value: "0",
          label: "发货数据",
        },
        {
          value: "1",
          label: "收货数据",
        },
        {
          value: "2",
          label: "经营费用数据",
        },
      ],
      editId: null,
      showEdit: false,
      rules: {
        // exportName: [
        //   { required: true, message: "请输入导出字段名", trigger: "change" },
        // ],
        name: [{ required: true, message: "请输入模板名称", trigger: "blur" }],
        type: [{ required: true, message: "请输入导出类型", trigger: "blur" }],
      },
      timeValue: [],
      exportTime: {
        startTime: "",
        endTime: "",
      },
    };
  },
  components: { Draggable },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      api
        .get("/v1/pc/export/list", {
          ...this.query,
          companyId: local.get("company_id"),
        })
        .then((res) => {
          this.tableData = res.data;
          this.total = res.total;
        });
    },
    handleExport(row) {
      api
        .fileget("/v1/pc/export/export", {
          id: row.id,
          startTime: this.exportTime.startTime,
          endTime: this.exportTime.endTime,
        })
        .then((res) => {
          if (res.code === 200) {
            this.exportTime = {
              startTime: "",
              endTime: "",
            };
            this.timeValue = [];
          }
        });
    },
    timeChoose(value) {
      this.exportTime.startTime =
        dayjs(value[0]).format("YYYY-MM-DD") +
        "T" +
        dayjs(value[0]).format("HH:mm:ss");
      this.exportTime.endTime =
        dayjs(value[1]).format("YYYY-MM-DD") +
        "T" +
        dayjs(value[1]).format("HH:mm:ss");
      console.log(this.exportTime, 123);

      // console.log(value);
    },
    handleDel(row) {
      api.post("/v1/pc/export/delete", { id: row.id }).then((res) => {
        if (res.code === 200) {
          this.$message({
            type: "success",
            message: "删除成功！",
          });
          if (this.tableData.length === 1 && this.query.page !== 1) {
            this.query.page--;
          }
          this.getList();
        }
      });
    },
    openEdit(row) {
      this.editId = row.id;
      this.form.name = row.name;
      this.form.type = String(row.type);
      this.myArray = JSON.parse(row.info).map((v) => {
        let name = "";
        console.log();
        if (this.form.type === "0") {
          name = this.sendList.filter((item) => item.value === v.fieldName)[0]
            .label;
        } else if (this.form.type === "1") {
          name = this.receiptList.filter(
            (item) => item.value === v.fieldName
          )[0].label;
        } else if (this.form.type === "2") {
          name = this.ReimbursementList.filter(
            (item) => item.value === v.fieldName
          )[0].label;
        }
        return {
          exportName: v.exportName,
          fieldName: v.fieldName,
          id: Number(v.sort) + 1,
          name,
        };
      });
      this.showEdit = true;
    },
    handleDelete(index) {
      this.myArray = this.myArray.filter((v, i) => i !== index);
    },
    handleChoose(item) {
      this.myArray.push({
        exportName: item.label,
        id: item.id,
        name: item.label,
        fieldName: item.value,
        remark: item.remark,
      });
      this.showAddPopver = false;
    },
    onSubmit() {},
    currentChange(e) {
      console.log(e);
    },
    onStart() {},
    onEnd(e) {},
    handleOpenPopver() {
      let data = [];
      if (this.form.type === "0") {
        data = this.sendList.map((v, i) => {
          return { ...v, id: i + 1 };
        });
      } else if (this.form.type === "1") {
        data = this.receiptList.map((v, i) => {
          return { ...v, id: i + 1 };
        });
      } else if (this.form.type === "2") {
        data = this.ReimbursementList.map((v, i) => {
          return { ...v, id: i + 1 };
        });
      }
      this.$set(this, "popoverList", data);
    },
    typeChange() {
      this.myArray = [];
    },
    handleSubmit() {
      if (!this.myArray.length) {
        return this.$message.error("请选择导出字段");
      }
      this.form.data = this.myArray.map((v, i) => {
        if (!v.exportName) {
          v.exportName = v.name;
        }
        let data = {
          fieldName: v.fieldName,
          exportName: v.exportName,
          sort: i,
        };
        if (v.remark) {
          data.remark = v.remark;
        }
        return data;
      });
      this.$refs["form"].validate((valid) => {
        if (valid) {
          api
            .post("/v1/pc/export/save", {
              ...this.form,
              companyId: local.get("company_id"),
            })
            .then((res) => {
              if (res.code === 200) {
                this.$message.success("保存成功");
                this.showAdd = false;
                this.form = {
                  name: "",
                  type: "",
                  data: [],
                };
                this.getList();
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleEdit() {
      if (!this.myArray.length) {
        return this.$message.error("请选择导出字段");
      }
      this.form.data = this.myArray.map((v, i) => ({
        fieldName: v.fieldName,
        exportName: v.exportName,
        sort: i,
      }));
      // console.log(this.form);
      this.$refs["form"].validate((valid) => {
        if (valid) {
          api
            .post("/v1/pc/export/update", {
              ...this.form,
              companyId: local.get("company_id"),
              id: this.editId,
            })
            .then((res) => {
              if (res.code === 200) {
                this.$message.success("修改成功");
                this.showEdit = false;
                this.form = {
                  name: "",
                  type: "",
                  data: [],
                };
                this.getList();
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped >
.p15 {
  padding: 15px;
}
.pt0 {
  padding-top: 0px;
}
.item {
  padding: 5px 3px;
}
.drag-box {
  border: 1px solid #0081ff;
  padding: 5px;
  padding-bottom: 20px;
  border-radius: 5px;
  /deep/ .el-form-item__label {
    font-size: 12px;
  }
  -webkit-user-select: none; /* 适用于Webkit浏览器 */
  -moz-user-select: none; /* 适用于Firefox */
  -ms-user-select: none; /* 适用于IE10+ */
  user-select: none; /* 标准语法 */
}
.add-dialog {
  /deep/.el-form-item__content {
    width: calc(100% - 90px);
  }
  .el-select {
    width: 100%;
  }
  /deep/.el-popover {
    border-radius: 10px;
  }
}
.unselectable {
  -webkit-user-select: none; /* 适用于Webkit浏览器 */
  -moz-user-select: none; /* 适用于Firefox */
  -ms-user-select: none; /* 适用于IE10+ */
  user-select: none; /* 标准语法 */
}

.scroll-box::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 12px;
  height: 8px;
}

.scroll-box::-webkit-scrollbar-thumb {
  border-radius: 6px;
  // background: rgba(255, 255, 255, 0.8);
  background: #bbb;
  transition: color 0.2s ease;
  cursor: pointer;
  border: 2px solid #fff;
}
.choose-box:hover {
  cursor: pointer;
}
/deep/.el-popover {
  padding: 10px 15px !important;
}
</style>
